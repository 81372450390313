.Voucher-Popup {
  width: 450px !important;
  border-radius: 16px !important;
  .popup-inner {
    padding: 30px 0px;
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-container {
        position: relative;
        height: 102px;
        width: 102px;
        border-radius: 50%;
        background: #2424241a;
        display: flex;
        align-items: center;
        justify-content: center;
        .close-cont {
          position: absolute;
          height: 20px;
          width: 20px;
          background-color: #f44336;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-family: Roboto !important;
          font-size: 12px;
          text-align: center;
          color: #fff;
          right: 10px;
          top: 2px;
        }
        img {
          height: 42px;
        }
      }
    }
    .delete-text {
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 18.75px;
      color: #252b42;
      text-align: center;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .delete-btn-conatiner {
      width: 250px;
      background-color: #242424;
      height: 42px;
      border-radius: 8px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
    }
    .cancel-btn-conatiner {
      width: 250px;
      height: 42px;
      border-radius: 8px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      color: #242424;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      border: 1px solid #242424;
      cursor: pointer;
      margin-top: 8px;
    }
  }
}

.Custom-Popup {
  width: 1100px !important;
  border-radius: 16px !important;
  padding: 20px;

  .popup-inner {
    .header-container {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-bottom: 40px;
      .header {
        .text {
          font-family: Roboto !important;
          font-size: 24px;
          font-weight: 500;
          text-align: left;
          color: #101725;
          margin-bottom: 5px;
        }
        .sub {
          font-family: Roboto !important;
          font-size: 13px;
          font-weight: 400;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #51566c;
        }
      }
      .close-cont {
        height: 30px;
        width: 30px;
        cursor: pointer;
      }
    }
    .inner-container {
      display: flex;
      width: 100%;
      .inner-container-left {
        width: 50%;
        .templates-conatiner {
          display: grid;
          grid-template-columns: auto auto;
          margin-top: 16px;
          gap: 8px;
          .template-item {
            width: 26rem;
            height: 12rem;
            position: relative;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
            .tick {
              position: absolute;
              right: 10px;
              bottom: 10px;
              height: 22px;
              width: 22px;
              background-color: #1bac4b;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 14px;
                width: 14px;
              }
            }
          }
        }
      }
      .inner-container-right {
        width: 50%;
        border-left: 1px solid #c4c4c4;
        margin-left: 20px;
        padding: 36px 20px;
        .selected-image {
          height: 22rem;
          width: 100%;
          margin-bottom: 50px;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          img {
            height: 100%;
            width: 100%;
          }
          .voucher-details {
            position: absolute;
            top: 0;
            width: 100%;
            .voucher-price {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 30px;
              right: 30px;
              font-size: 20px;
              color: #000;
              font-weight: 600;
              font-family: Roboto !important;
              width: 80px;
              height: 80px;
              background-color: #fff;
              border-radius: 50%;
            }
            .company-name {
              font-size: 20px;
              color: #fff;
              position: absolute;
              top: 30px;
              left: 29%;
            }
            .gift-voucher-text {
              position: absolute;
              top: 81px;
              font-size: 24px;
              font-family: Roboto !important;
              color: #fff;
              left: 32%;
              border-top: 1px solid #fff;
              padding-top: 10px;
              border-bottom: 1px solid #fff;
              padding-bottom: 10px;
            }
            .sub-text-vopucher {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: 70%;
              top: 13rem;
              left: 0;
              right: 0;
              line-height: 12px;
              font-size: 10px;
              color: #fff;
              font-family: Roboto !important;
              margin: auto;
              text-align: center;
            }
            .valid-text {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: max-content;
              margin: auto;
              top: 17.5rem;
              left: 0;
              right: 0;
              font-size: 12px;
              color: #000;
              font-weight: 600;
              font-family: Roboto !important;
              background-color: #fff;
              border-radius: 14px;
              padding-left: 20px;
              padding-right: 20px;
              .valid-date {
                font-size: 12px;
                color: #000;
                margin-left: 3px;
                font-weight: 400;
              }
            }
            .qr-code {
              position: absolute;
              top: 140px;
              background-color: #fff;
              height: 50px;
              width: 50px;
              left: 10px;
              img {
                height: 100%;
                width: 100%;
              }
            }
            .qr-text {
              position: absolute;
              top: 190px;
              font-size: 10px;
              color: #fff;
              font-weight: 300;
              font-family: Roboto !important;
              left: 9px;
            }
            .content {
              position: absolute;
              top: 160px;
              left: 0;
              right: 0;
              font-size: 10px;
              color: #000;
              font-weight: 500;
              font-family: Roboto !important;
            }
          }
        }
        .input-container {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .input-cont {
            width: 60%;
          }
          .input-text {
            width: 60%;
            display: flex;
            margin-top: 10px;
            justify-content: flex-end;
            font-size: 16px;
            margin-bottom: 20px;
          }
          .input-cont-date {
            width: 100%;
          }
        }
        .error-text {
          color: #ff0000;
          font-size: 12px;
          margin-top: 10px;
        }
        .rest-btn-conatiner {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 16px;
          padding-left: 10px;
          padding-right: 10px;
          margin-top: 30px;
          .checkout-button {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            background-color: #242424;
            border-radius: 4px;
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #fff;
            cursor: pointer;
            .loader-btn {
              border: 2px solid rgba(255, 255, 255, 0.3);
              border-top: 2px solid #ffffff;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              animation: spin 0.7s linear infinite;
              margin-right: 10px;
            }
          }
        }
        .item-cart-qty-container {
          display: flex;
          align-items: center;
          border: 1px solid #e4e9ee;
          width: 90px;
          border-radius: 8px;
          height: 35px;
          .qty-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 35px;
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 25.6px;
            text-align: center;
            color: #0b0f0e;
          }
          .quantity-ic-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 35px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.ant-picker-dropdown {
  z-index: 100000 !important;
}
@media only screen and (max-width: 600px) {
  .Custom-Popup {
    .popup-inner {
      .inner-container {
        display: block;
        .inner-container-left {
          width: 100%;
          .templates-conatiner {
            .template-item {
              width: 17rem;
              height: 9rem;
            }
          }
        }
        .inner-container-right {
          width: 100%;
          margin-left: 0;
          border-left: none;
          padding: 0px;
          margin-top: 20px;
          .selected-image {
            background-size: contain;
            margin-bottom: 20px;
            height: 16rem;

            .voucher-details {
              .voucher-price {
                width: 60px;
                height: 60px;
                font-size: 16px;
                top: 10px;
                right: 10px;
              }
              .company-name {
                font-size: 14px;
              }
              .gift-voucher-text {
                position: absolute;
                top: 60px;
                font-size: 16px;
                font-family: Roboto !important;
                color: #fff;
                width: max-content;
                margin: auto;
                padding-top: 5px;
                padding-bottom: 5px;
              }
              .valid-text {
                top: 13rem;
                font-size: 8px;
                left: -20px;
                padding-left: 10px;
                padding-right: 10px;
                .valid-date {
                  font-size: 8px;
                }
              }
              .sub-text-vopucher {
                font-size: 6px;
                top: 10rem;
                width: 60%;
              }
              .qr-code {
                top: 80px;
              }
              .qr-text {
                top: 130px;
              }

              .content {
                position: absolute;
                top: 160px;
                left: 0;
                right: 0;
                font-size: 10px;
                color: #000;
                font-weight: 500;
                font-family: Roboto !important;
              }
            }
          }
        }
      }
    }
  }
}
