.VoucherMenuAuth {
  padding: 64px;
  background: linear-gradient(#f7f7f7 0px, #f7f7f7 250px, white 250px);
  position: relative;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 36px;
    display: flex;
    align-items: center;
    font-family: Roboto !important;
  }
  .VoucherMenuAuth-inner {
    padding-top: 40px;
    .header-text-cont {
      font-family: Roboto !important;
      font-size: 36px;
      font-weight: 700;
      line-height: 57.6px;
      text-align: left;
      color: #101725;
    }
    .auth-container-wrapper {
      display: flex;
      column-gap: 32px;
      margin-top: 54px;
      .auth-container-wrapper-left {
        width: 55%;
        .auth-container-left-inner {
          background-color: #fff;
          border: 1px solid #e4e9ee;
          border-radius: 12px;
          padding: 49px 52px;
          .form-container {
            .error-text {
              font-family: Roboto !important;
              font-size: 12px;
              text-align: left;
              color: #e61e1e;
            }
            .form-header {
              font-family: Roboto !important;
              font-size: 17px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #3b4159;
              margin-bottom: 24px;
            }
            .column-form-wrapper {
              display: flex;
              column-gap: 10px;
              .input-containers {
                width: 100%;
                .eco-input-text .eco-input-text__wrapper input[type="default"],
                .eco-input-text .eco-input-text__wrapper input[type="email"],
                .eco-input-text .eco-input-text__wrapper input[type="number"],
                .eco-input-text .eco-input-text__wrapper input[type="password"],
                .eco-input-text .eco-input-text__wrapper input[type="tel"],
                .eco-input-text .eco-input-text__wrapper input[type="text"] {
                  border: 1px solid #d1d8dd !important;
                }
                .input-empty {
                  border: 1px solid #d1d8dd !important;
                  border-radius: 4px;
                  padding: 7px 10px;
                  margin-top: 8px;
                }
                .form-label-wrapper {
                  display: flex;
                  span {
                    font-family: Roboto !important;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: #e61e1e;
                    margin-top: -2px;
                  }

                  .label-text {
                    font-family: Roboto !important;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: #101725;
                  }
                }
                .dropdown {
                  padding: 8px;
                  margin-top: 8px;
                  border-radius: 4px;
                }
                .select {
                  width: 100%;
                }

                .ant-select-focused:where(
                    .css-dev-only-do-not-override-98ntnt
                  ).ant-select-outlined:not(.ant-select-disabled):not(
                    .ant-select-customize-input
                  ):not(.ant-pagination-size-changer)
                  .ant-select-selector {
                  border: 1px solid #d1d8dd !important;
                  box-shadow: none !important;
                  outline: 0;
                }
                :where(
                    .css-dev-only-do-not-override-98ntnt
                  ).ant-select-outlined:not(.ant-select-disabled):not(
                    .ant-select-customize-input
                  ):not(.ant-pagination-size-changer):hover
                  .ant-select-selector {
                  border: 1px solid #d1d8dd !important;
                }
                .ant-select:not(.ant-select-disabled):hover
                  .ant-select-selector {
                  border: 1px solid #d1d8dd !important;
                  border-right-width: 1px;
                }
              }
            }
          }
          .input-containers {
            margin-bottom: 24px;
            .eco-input-text .eco-input-text__wrapper input[type="default"],
            .eco-input-text .eco-input-text__wrapper input[type="email"],
            .eco-input-text .eco-input-text__wrapper input[type="number"],
            .eco-input-text .eco-input-text__wrapper input[type="password"],
            .eco-input-text .eco-input-text__wrapper input[type="tel"],
            .eco-input-text .eco-input-text__wrapper input[type="text"] {
              border: 1px solid #d1d8dd !important;
            }
            .form-label-wrapper {
              display: flex;
              span {
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: #e61e1e;
                margin-top: -2px;
              }
              .label-text {
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: #101725;
              }
            }
          }
          .remember-me-cont {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            .forget-password {
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 500;
              line-height: 28px;
              text-align: left;
              color: #006dae;
            }
            .remember-me {
              display: flex;
              align-items: center;
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 500;
              line-height: 28px;
              text-align: left;
              color: #0b0f0e;
              column-gap: 10px;

              .check-box {
                height: 18px;
                width: 18px;
                border-radius: 4px;
                background: #006dae;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                  height: 8px;
                }
              }
              .empty-check {
                height: 18px;
                width: 18px;
                border-radius: 4px;
                cursor: pointer;
                border: 1.5px solid #c4c8cc;
              }
            }
          }
          .loader-btn {
            border: 2px solid rgba(255, 255, 255, 0.3);
            border-top: 2px solid #ffffff;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 0.7s linear infinite;
            margin-right: 10px;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          .button-conatiner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: #006dae;
            padding: 10px 0px;
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #fff;
            border-radius: 8px;
            cursor: pointer;
          }
          .verification-content {
            font-family: Roboto !important;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #818b9c;
            margin-top: 24px;
          }
          .create-content {
            font-family: Roboto !important;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #818b9c;
            margin-top: 10%;
            margin-bottom: 39px;
          }
          .reg-link {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-top: 24px;
            .reg-text {
              font-family: Roboto !important;
              font-size: 14px;
              line-height: 28px;
              text-align: left;
              color: #818b9c;
            }
            .reg-link-text {
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 500;
              line-height: 28px;
              text-align: left;
              color: #006dae;
              cursor: pointer;
            }
          }
        }
      }
      .auth-container-wrapper-right {
        width: 25%;
        .auth-container-right-inner {
          background-color: #fff;
          border: 1px solid #e4e9ee;
          border-radius: 12px;
          padding: 32px 24px;
          height: 60vh;

          .auth-types {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .auth-type-text {
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              line-height: 22.4px;
              letter-spacing: -0.20000000298023224px;
              text-align: left;
              color: #0b0f0e;
            }
            .arrow-ic {
              height: 12px;
              width: 12px;
            }
          }
          .auth-actions {
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 25.6px;
            text-align: left;
            color: #818b9c;
            margin-bottom: 12px;
            cursor: pointer;
          }
          .border-line {
            height: 1px;
            width: 100%;
            background-color: #e4e9ee;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .VoucherMenuAuth {
    padding: 0px;
    .VoucherMenuAuth-inner {
      .header-text-cont {
        font-size: 24px;
        margin-left: 20px;
      }
      .auth-container-wrapper {
        margin-top: 20px;
        display: block !important;
        .auth-container-wrapper-left {
          width: 100% !important;
          margin: auto;
          .auth-container-left-inner {
            padding: 32px 24px;
            .form-container {
              .column-form-wrapper {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
