.Footer-Voucher {
  width: 100%;

  margin-top: 20vh;
  border-top: 1px solid #e4e9ee;
  padding-top: 30px;
  margin-bottom: 20px;
  .footer-innwr {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;

    .footer-innwr-right {
      //   display: flex;
      //   column-gap: 40px;

      .logo-container {
        margin-bottom: 20px;
        img {
          height: 40px;
        }
      }
      .menu-header-inner-header {
        background-color: #fff;
        .name {
          font-family: Roboto !important;
          font-size: 20px;
          font-weight: 800;
          line-height: 22.4px;
          letter-spacing: -0.20000000298023224px;
          text-align: left;
          color: #242424;
        }
        .address {
          margin-top: 10px;
          font-family: Roboto !important;
          font-size: 15px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          color: #5e5e5e;
          text-transform: capitalize;
        }
      }
    }
    .menu-header-inner {
      text-align: left;

      .name {
        font-family: Roboto !important;
        font-size: 20px;
        font-weight: 800;
        line-height: 22.4px;
        letter-spacing: -0.20000000298023224px;

        color: #424242;
      }
      .address {
        margin-top: 20px;
        font-family: Roboto !important;
        font-size: 17px;
        font-weight: 400;
        color: #5e5e5e;
        .cont {
          display: flex;
          column-gap: 10px;
          margin-top: 10px;
          align-items: flex-start;
          img {
            height: 14px;
            width: 14px;
            margin-top: 3px;
            filter: brightness(0) saturate(100%) invert(36%) sepia(3%)
              saturate(9%) hue-rotate(0deg) brightness(97%) contrast(91%);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Footer {
    .footer-innwr {
      padding-right: 0px;
      flex-direction: column-reverse;
      .menu-header-inner {
        margin-bottom: 30px;
      }
    }
  }
}
