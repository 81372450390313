.SearchPage {
  height: 100vh !important;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #fff;
  z-index: 10000;
  .Voucher-search-wrapper-cont {
    width: 100%;
    position: fixed;
    margin: auto;
    background-color: #006dae;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .arrow-conat {
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrowIc {
        height: 16px;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }

    .Voucher-search-container {
      background-color: #fff;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      border-radius: 40px;
      width: 90%;
      .Voucher-search-input {
        border: none;
        outline: none;
        width: 100%;
        font-family: Roboto !important;
      }
      .close-image {
        height: 22px;
        width: 22px;
        cursor: pointer;
      }
    }
  }
  .Voucher-searched-container {
    background-color: #fff;
    width: 100%;
    margin-top: -4px;
    padding: 12px 12px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-top: none;
    margin-top: 60px;
    height: max-content;
    .empty-search {
      padding: 20px;
      text-align: center;
      font-family: Roboto !important;
      font-size: 15px;
      color: #3b4159;
      text-wrap: wrap;
    }
    .searched-item-cont {
      display: flex;
      align-items: center;
      column-gap: 20px;
      padding: 10px 8px;
      cursor: pointer;
      border-bottom: 1px solid #f1f1f1;
      .searched-image-cont {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 2px;
      }
      .searched-title {
        font-family: Roboto !important;
        font-size: 14px;
        font-weight: 500;
        color: #101725;
      }
      .searched-cat {
        font-family: Roboto !important;
        font-size: 13px;
        color: #7c8091;
      }
    }
  }
}
