.Hospitality-navbar-Layout {
  .loader-btn {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .layout1 {
    min-height: 12.75rem;

    .column-styles {
      padding: 0.625rem;
      .column {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 6px 14px -6px #18274b1f;
        border: 1px solid #e0e0e093;
        .item-details {
          display: flex;
          column-gap: 0.6235rem;
          width: 75%;
          .item-dela-cont {
            height: 12.75rem;
            width: 25.875rem;
            border-bottom-left-radius: 0.5rem;
            border-top-left-radius: 0.5rem;

            position: relative;
            .item-image {
              height: 12.75rem;
              width: 25.875rem;
              border-bottom-left-radius: 0.5rem;
              border-top-left-radius: 0.5rem;
              object-fit: cover;
              cursor: pointer;
            }
            .dea-cont {
              position: absolute;
              height: 30px;
              width: 30px;
              top: 12px;
              left: 10px;
              border-radius: 4px;
              background-color: #1bac4b;
              display: flex;
              align-items: center;
              justify-content: center;
              .deal {
                height: 22px;
                max-height: 22px;
              }
            }
          }

          .item-des {
            padding: 1.25rem 1.5rem;
            width: 100%;
            .item-des-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .name {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: left;
                color: #101725;
              }
              .price {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: left;
                color: #006dae;
              }
            }

            .details {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #7c8091;
              margin-top: 5px;
            }
          }
        }
        .column-right-container {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          .column-right-container-inner {
            width: 80%;
            .quantity {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .icon-cont {
                height: 44px;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border-radius: 8px;
                cursor: pointer;
                border: 1px solid #e4e9ee;
                .delete-icon {
                  height: 18px;
                }
                .icon {
                  height: 14px;
                  width: 14px;
                  filter: brightness(0) saturate(100%) invert(22%) sepia(88%)
                    saturate(1930%) hue-rotate(182deg) brightness(101%)
                    contrast(103%);
                }
              }
              .empty-icon-cont {
                height: 44px;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f3f3f3;
                border-radius: 8px;
                cursor: pointer;
                border: 1px solid #e4e9ee;
                .icon {
                  height: 14px;
                  width: 14px;
                  filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                    saturate(5%) hue-rotate(315deg) brightness(97%)
                    contrast(88%);
                }
              }
              .qty-cont {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Roboto !important;
                font-size: 18px;
                font-weight: 500;
              }
            }
            .add-to-cart-btn {
              width: 100%;
              background-color: #006dae;
              border-radius: 0.25rem;
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: #fff;
              padding: 0.625rem 0;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .add-to-cart-btn-custom {
              width: 100%;
              border-radius: 0.25rem;
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: #006dae;
              padding: 0.625rem 0;
              margin-top: 0.4rem;
              border: 1px solid #006dae;
              cursor: pointer;
              a {
                color: #006dae;
                margin: 0;
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }
  .layout2 {
    width: 95%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
    .column-styles {
      .column {
        min-height: 16.93rem;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 6px 14px -6px #18274b1f;
        border: 1px solid #e0e0e093;
        .item-details {
          display: flex;
          column-gap: 0.6235rem;
          width: 100%;
          .item-dela-cont {
            height: 100%;
            min-height: 16.93rem;
            width: 19.25rem;
            border-bottom-left-radius: 0.5rem;
            border-top-left-radius: 0.5rem;

            position: relative;
            .item-image {
              height: 16.93rem;
              width: 19.25rem;
              border-bottom-left-radius: 0.5rem;
              border-top-left-radius: 0.5rem;
              object-fit: cover;
              cursor: pointer;
            }
            .dea-cont {
              position: absolute;
              height: 30px;
              width: 30px;
              top: 12px;
              left: 10px;
              border-radius: 4px;
              background-color: #1bac4b;
              display: flex;
              align-items: center;
              justify-content: center;
              .deal {
                height: 22px;
                max-height: 22px;
              }
            }
            .cart-btn-customize {
              display: none !important;
            }
          }

          .item-des {
            padding: 1.25rem 1.5rem;
            width: 100%;
            .item-des-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .name {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: left;
                color: #101725;
              }
              .price {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: left;
                color: #006dae;
              }
            }

            .details {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #7c8091;
              margin-top: 5px;
              height: 48px;
            }
            .price-container {
              width: 100%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              margin-top: 1.5rem;
              .quantity {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
                .icon-cont {
                  height: 44px;
                  width: 44px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #fff;
                  border-radius: 8px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .delete-icon {
                    height: 18px;
                  }
                  .icon {
                    height: 14px;
                    width: 14px;
                    filter: brightness(0) saturate(100%) invert(22%) sepia(88%)
                      saturate(1930%) hue-rotate(182deg) brightness(101%)
                      contrast(103%);
                  }
                }
                .empty-icon-cont {
                  height: 44px;
                  width: 44px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f3f3f3;
                  border-radius: 8px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .icon {
                    height: 14px;
                    width: 14px;
                    filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                      saturate(5%) hue-rotate(315deg) brightness(97%)
                      contrast(88%);
                  }
                }
                .qty-cont {
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: Roboto !important;
                  font-size: 18px;
                  font-weight: 500;
                }
              }
              .add-to-cart-btn {
                width: 100%;
                background-color: #006dae;
                border-radius: 0.25rem;
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                padding: 0.625rem 0;
                margin-top: 4px;
                border: 1px solid #006dae;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .add-to-cart-btn-custom {
                width: 100%;
                border-radius: 0.25rem;
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #006dae;
                padding: 0.625rem 0;
                margin-top: 0.4rem;
                border: 1px solid #006dae;
                cursor: pointer;
                a {
                  color: #006dae;
                  margin: 0;
                  text-decoration: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .layout3 {
    width: 95%;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    gap: 2rem;
    .column-styles {
      .column {
        height: 37rem;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 6px 14px -6px #18274b1f;
        border: 1px solid #e0e0e093;
        .item-details {
          column-gap: 0.6235rem;
          width: 100%;
          .item-dela-cont {
            height: 18.75rem;
            width: 100%;
            border-bottom-left-radius: 0.5rem;
            border-top-left-radius: 0.5rem;

            position: relative;
            .item-image {
              height: 18.75rem;
              width: 100%;
              border-bottom-left-radius: 0.5rem;
              border-top-left-radius: 0.5rem;
              object-fit: cover;
              cursor: pointer;
            }
            .dea-cont {
              position: absolute;
              height: 30px;
              width: 30px;
              top: 12px;
              left: 10px;
              border-radius: 4px;
              background-color: #1bac4b;
              display: flex;
              align-items: center;
              justify-content: center;
              .deal {
                height: 22px;
                max-height: 22px;
              }
            }
            .cart-btn-customize {
              display: none !important;
            }
          }

          .item-des {
            padding: 1.25rem 1.5rem;
            width: 100%;
            .item-des-title {
              display: flex;
              justify-content: space-between;
              .name {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: left;
                color: #101725;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              .price {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: left;
                color: #006dae;
              }
            }

            .details {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #7c8091;
              margin-top: 5px;
              min-height: 48px;
            }
            .price-container {
              width: 100%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              margin-top: 1.5rem;
              .price {
                display: none !important;
              }
              .quantity {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
                .icon-cont {
                  height: 44px;
                  width: 44px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #fff;
                  border-radius: 8px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .delete-icon {
                    height: 18px;
                  }
                  .icon {
                    height: 14px;
                    width: 14px;
                    filter: brightness(0) saturate(100%) invert(22%) sepia(88%)
                      saturate(1930%) hue-rotate(182deg) brightness(101%)
                      contrast(103%);
                  }
                }
                .empty-icon-cont {
                  height: 44px;
                  width: 44px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f3f3f3;
                  border-radius: 8px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .icon {
                    height: 14px;
                    width: 14px;
                    filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                      saturate(5%) hue-rotate(315deg) brightness(97%)
                      contrast(88%);
                  }
                }
                .qty-cont {
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: Roboto !important;
                  font-size: 18px;
                  font-weight: 500;
                }
              }
              .add-to-cart-btn {
                width: 100%;
                background-color: #006dae;
                border-radius: 0.25rem;
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                padding: 0.625rem 0;
                margin-top: 4px;
                border: 1px solid #006dae;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .add-to-cart-btn-custom {
                width: 100%;
                border-radius: 0.25rem;
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #006dae;
                padding: 0.625rem 0;
                margin-top: 0.4rem;
                border: 1px solid #006dae;
                cursor: pointer;
                a {
                  color: #006dae;
                  margin: 0;
                  text-decoration: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .title-menu {
    font-family: Roboto !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #101725;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .Hospitality-navbar-Layout {
    padding: 20px;
    .layout3 {
      display: block !important;
      width: 100%;
      min-height: 122px;
      .column-styles {
        padding: 0.625rem;
        .column {
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          border-radius: 0;
          box-shadow: 0 !important;
          border: none;
          padding-bottom: 10px;
          border-bottom: 1px solid #e3e7ec;
          height: 125px;

          .item-details {
            display: flex;
            column-gap: 0.6235rem;
            width: 100%;
            align-items: center;
            .item-dela-cont {
              height: 85px;
              width: 122px;
              .item-image {
                height: 85px;
                width: 122px;
              }
              .cart-btn-customize {
                display: flex !important;
                position: absolute;
                column-gap: 8px;
                padding: 6px 10px;
                border-radius: 8px;
                background-color: #1bac4b;
                font-family: Roboto !important;
                font-size: 10px;
                font-weight: 400;
                line-height: 11.72px;
                text-align: left;
                color: #fff;
                left: 0;
                right: 0;
                width: max-content;
                margin: auto;
                bottom: 8px;
                text-transform: uppercase;
                cursor: pointer;
                align-items: center;
                img {
                  height: 9px;
                }
              }
            }

            .item-des {
              padding: 1.25rem 1.5rem;
              width: 100%;
              .item-des-title {
                display: block;
                .name {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 19.6px;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: #101725;
                }
                .price {
                  display: none;
                }
              }

              .details {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-family: Roboto !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 19.2px;
                text-align: left;
                color: #7c8091;
                margin-top: 5px;
              }
              .price-container {
                width: 100%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                margin-top: 8px;
                justify-content: space-between;
                .quantity {
                  margin-right: 0px;
                }
                .price {
                  display: flex !important;
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 19.6px;
                  letter-spacing: -0.20000000298023224px;
                  color: #006dae;
                }
                .add-to-cart-btn {
                  width: 60%;
                  background-color: #006dae;
                  border-radius: 0.25rem;
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                  padding: 0.5rem 0;
                  margin-top: 4px;
                  border: 1px solid #006dae;
                  cursor: pointer;
                }

                .add-to-cart-btn-custom {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .layout2 {
      margin: auto;
      gap: 16px;
      .column-styles {
        width: 160px;
        .column {
          display: block !important;
          min-height: 258px;
          box-shadow: none !important;
          border: none !important;
          .item-details {
            display: block !important;
            .item-dela-cont {
              min-height: 130px;
              width: 160px;
              .item-image {
                min-height: 130px;
                width: 160px;
                border-radius: 8px;
              }
              .cart-btn-customize {
                display: flex !important;
                position: absolute;
                column-gap: 8px;
                padding: 6px 10px;
                border-radius: 8px;
                background-color: #1bac4b;
                font-family: Roboto !important;
                font-size: 10px;
                font-weight: 400;
                line-height: 11.72px;
                text-align: left;
                color: #fff;
                left: 0;
                right: 0;
                width: max-content;
                margin: auto;
                bottom: 8px;
                text-transform: uppercase;
                cursor: pointer;
                align-items: center;
                img {
                  height: 9px;
                }
              }
            }
            .item-des {
              padding: 0;
              .item-des-title {
                align-items: start;
                margin-top: 23px;
                .name {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 19.6px;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                }
                .price {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 19.6px;
                  letter-spacing: -0.20000000298023224px;
                }
              }
              .details {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1 !important;
                font-family: Roboto !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 19.2px;
                text-align: left;
                color: #7c8091;
                margin-top: 5px;
              }
              .price-container {
                .quantity {
                  margin-right: 0px;
                }
                .add-to-cart-btn-custom {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
