.Voucher-OrderPicker {
  padding: 4rem;
  .header-text {
    font-family: Roboto !important;
    font-size: 28px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: #101725;
  }
  .sub-text {
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #51566c;
  }
  .proceed-btn {
    background-color: #242424;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    width: 340px;
    font-family: Roboto !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
    border-radius: 8px;
    margin-top: 32px;
    cursor: pointer;
  }
  .order-type-containers {
    margin-top: 32px;
    .order-type-container {
      cursor: pointer;
      width: 340px;
      display: flex;
      padding: 16px;
      border: 2px solid #e9f8f5;
      border-radius: 20px;
      margin-bottom: 12px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #101725;
      align-items: center;
      justify-content: space-between;
      .order-type-container-left {
        display: flex;
        align-items: center;
        column-gap: 12px;
        .radio-type {
          width: 18px;
          height: 18px;
          border: 1px solid #78828a;
          border-radius: 50%;
        }
        .radio-type-selected {
          width: 18px;
          height: 18px;
          border: 1px solid #242424;
          border-radius: 50%;
          background-color: #242424;
          display: flex;
          align-items: center;
          justify-content: center;
          .radio-type-selected-inner {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
      .order-type-container-right {
        background-color: #24242414;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .OrderPicker {
    padding: 0;
    width: 85%;
    margin: auto;
    margin-top: 32px;
    .order-type-containers {
      width: 100%;
      .order-type-container {
        width: 100%;
      }
    }
    .proceed-btn {
      width: 100%;
    }
  }
}
