.Modal {
  width: 450px !important;
  border-radius: 16px !important;
  .modal-inner {
    padding: 30px 0px;
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-container {
        height: 102px;
        width: 102px;
      }
    }
    .modal-header-text {
      text-align: center;
      font-family: Roboto !important;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.44px;
      color: #252b42;
      margin-top: 32px;
    }
    .modal-sub-text {
      font-family: Roboto !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 25.6px;
      text-align: center;
      color: #818b9c;
      margin-top: 12px;
    }
    .btn-container {
      width: 55%;
      margin: auto;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: -0.20000000298023224px;
      text-align: center;
      color: #ffffff;
      background: #006dae;
      padding: 10px 0px;
      border-radius: 8px;
      margin-top: 32px;
      cursor: pointer;
    }
  }
}
